.user-message {
    display: flex;
    margin: 100px auto 20px;
    text-align: center;
    width: 300px;
    height: 100px;
}

.user-message p {
    text-align: start;
}