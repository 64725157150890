// color vars

$colorDark: #46CD84;
$colorGreen: black;


// mixin

@mixin centerSelf {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

@mixin stretchSelf {
  width: 100%;
  height: 100%;
}


// general

.app {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  position: relative;
  overflow: hidden;
  background: transparent;

}


// Sonar
.sonar {

  width: 100%;
  padding-top: 100%;
  height: 0;
  position: relative;

  //@media (min-width: 800px) {
  //  width: 40%;
  //  padding-top: 40%;
  //}


  &__container {
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    @include centerSelf;
    border: 1px solid rgba($colorGreen, 0.6);
    border-radius: 50%;
  }

}


// distance circles

.distance {
  @include centerSelf;
  @include stretchSelf;
  z-index: 10;
}

.distance__circle {
  background: transparent;
  box-shadow: inset 1px 0 0 0 rgba($colorGreen, 0.4),
  inset -1px 0 0 0 rgba($colorGreen, 0.4),
  inset 0 1px 0 0 rgba($colorGreen, 0.4),
  inset 0 -1px 0 0 rgba($colorGreen, 0.4);
  border-radius: 50%;
  @include centerSelf;

}

// draw circles
@for $distanceCircle from 1 through 5 {
  .distance__circle:nth-child(#{$distanceCircle}) {
    width: 0% + ($distanceCircle * 20);
    height: 0% + ($distanceCircle * 20);
  }
}


// degree lines

.degree {
  @include stretchSelf;
  @include centerSelf;
  z-index: 10;
}

.degree__line {
  height: 50%;
  width: 1px;
  background: rgba($colorGreen, 0.4);

  left: 50%;
  bottom: 50%;
  position: absolute;
  transform-origin: bottom center;
  transform: translate(-50%, 0) rotateZ(0);

  &:before {
    content: "360°";
    position: absolute;
    color: rgba($colorGreen, 0.4);
    font-family: cursive;
    font-size: 1rem;
    top: -34px;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(0deg);
  }

  &:after {
    position: absolute;
    color: rgba($colorGreen, 0.6);
    font-family: cursive;
    font-size: 1rem;
    bottom: -44px;
    left: 50%;
    transform: translate(-50%, -50%) rotateZ(180deg);
  }

}


// draw lines
@for $degreeLines from 1 through 12 {
  .degree__line:nth-child(#{$degreeLines}) {

    transform: translate(-50%, 0) rotateZ(($degreeLines * 30) - 30deg);

    $degreeValueLow: ($degreeLines * 30) - 30;
    $degreeValueHigh: ($degreeLines * 30) + 150;

    &:before {
      content: "#{$degreeValueLow}""°";
      transform: translate(-50%, 0) rotateZ(-#{$degreeValueLow}+"deg");
    }

  }

}


// rotary radar
.rotary {

  @include stretchSelf;
  background: conic-gradient(rgba($colorDark, 1) 20%, rgba($colorDark, 0) 86%, $colorGreen);
  overflow: hidden;
  border-radius: 50%;
  animation: rotating 5.4s linear infinite;
  transform-origin: center center;
  z-index: -1;
  position: relative;


}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

// matrix

.matrix {
  @include centerSelf;
  @include stretchSelf;
  background-image: linear-gradient(0deg, rgba($colorGreen, 0.1) 25%, $colorDark 25%, $colorDark 50%, rgba($colorGreen, 0.1) 50%, rgba($colorGreen, 0.1) 75%, $colorDark 75%, $colorDark 100%);
  background-size: 4.00px 4.00px;
  border-radius: 50%;

}


// display


.display {
  @include stretchSelf;
  @include centerSelf;
  z-index: -2;
}


// ships
.ship-1 {
  width: 2.5%;
  height: 2.5%;
  background: $colorGreen;
  position: absolute;
  top: 21%;
  left: 45%;
  border-radius: 50%;
  box-shadow: 0px 0px 6px 2px $colorGreen;

}

.ship-2 {
  width: 2.5%;
  height: 2.5%;
  background: $colorGreen;
  position: absolute;
  top: 14%;
  left: 78%;
  border-radius: 50%;
  box-shadow: 0px 0px 6px 2px $colorGreen;
}

.ship-3 {
  width: 2.5%;
  height: 2.5%;
  background: $colorGreen;
  position: absolute;
  top: 63%;
  left: 22%;
  border-radius: 50%;
  box-shadow: 0px 0px 6px 2px $colorGreen;
}

.peak {
    position: absolute;
  top: 83%;
  left: 42%;
  z-index: 200;
}

.peak svg {
  width: 25px;
  height: 25px;
}