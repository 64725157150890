@import url('../../../../commonStyles.css');

.cities-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    max-height: 100vh;
    overflow-y: auto;
    width: 350px;
    margin: 0 auto;
    gap: 20px;
    padding-bottom: 70px;
}