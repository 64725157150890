.flight-segment {
    display: flex;
}

.layover-info {
    width: 90%;
    margin: 0 auto;
    color: var(--element-background);
    font-size: 12px;
    font-weight: bold;
    background: var(--background-less-dark);
    text-align: center;
}

.monitor-layover-info {
    width: 90%;
    margin: 0 auto;
    color: var(--disabled-text);
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    font-family: 'Upheaval TT BRK', sans-serif;
}

.layover-text {
    color: var(--border-color);
    margin: 10px auto;
    padding: 4px 0;
}

.flight-details-departure, .flight-segment-departure {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: center;
    margin-right: 10px;
    margin-left: 5px;
    font-family: 'Upheaval TT BRK', sans-serif;
    color: var(--disabled-text);
    width: 85px;
}

.flight-segment-departure, .flight-segment-arrival {
    color: var(--background-dark) !important;
}

.flight-details-arrival, .flight-segment-arrival {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    margin-left: 10px;
    font-family: 'Upheaval TT BRK', sans-serif;
    color: var(--disabled-text);
    text-align: end;
    align-items: end;
    width: 85px;
}

.departure-time, .arrival-time, .transfer-date {
    font-size: 13px;
    font-family: "IBM Plex Mono";
}

.departure-date, .arrival-date {
    font-size: 11px;
    font-family: "IBM Plex Mono";
}

.address {
    font-size: 10px;
    line-height: 13px;
    max-height: 50px;
    overflow: hidden;
    width: 85px !important;
}

.transfer-date {
    font-size: 11px;
}

.departure-time svg, .arrival-time svg {
    width: 16px;
    height: 16px;
    color: var(--disabled-text);
}

.airport-text {
    font-size: 32px;
}

.time-gate, .carrier-baggage-stops {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    height: 50px;
    font-size: 14px;
    width: 100%;
}

.flight-check-in {
    font-size: 13px;
}

.flight-duration-container, .segments-duration-container, .transfer-duration-container {
    display: flex;
    flex-direction: column;
    width: 90px;
    align-items: center;
    text-align: center;
    margin: auto;
}

.additional-flight-details, .additional-traveler-details {
    display: flex;
    margin-bottom: 4px;
    justify-content: center;
}

.additional-flight-details {
    gap: 5px;
}

.additional-traveler-details {
    gap: 20px;
}

.flight-segments-container {
    flex-direction: column;
}

.segments-container {
    margin-bottom: 15px;
    border-radius: 5px !important;
}

.terminal-info {
    margin-right: 5px;
}

.monitor-subtitle {
    color: var(--background-dark);
    font-size: 16px;
    margin-left: 8px;
    margin-bottom: 0;
}

.more-details-container {
    margin-bottom: 200px;
}

.flight-info-details {
    display: flex;
    flex-direction: row;
}

.flight-info-details p {
    color: var(--border-color) !important;
    font-size: 14px;
    width: 230px;
}