.left-images-outer, .right-images-outer {
    background-color: var(--background-dark);
    transition: background-color 0.3s ease;
    border: 2px solid var(--border-color);
    width: 168px;
    height: 49px;
}

.left-images-outer {
    width: 166px;
}

.left-images-outer, .left-images-inner {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
}

.right-images-outer, .right-images-inner {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
}

.right-images-outer {
    border-left: none !important;
}

.left-images-inner, .right-images-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: var(--background-less-dark);
    transition: transform 0.3s ease;
    border-bottom: 2px solid var(--border-color);
    border-top: 2px solid var(--border-color);
    position: relative;
    top: -2px;
}

.left-images-inner.translate, .right-images-inner.translate {
    transform: translateY(8px); /* Adjust the translateY value to match your design */
    background: var(--navigation-button-inner);
}

.left-images-inner.translate svg path, .right-images-inner.translate svg path {
    stroke: white !important; /* Set the stroke color to white */
}