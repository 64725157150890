.tag-wrapper {
    width: var(--container-width-smartphone);
    background: var(--element-background);
    border-radius: 5px;
    margin: 0 auto;
    height: 60vh;
    display: flex;
    flex-direction: column;
}

.tags-container {
    height: 150px;
}

.tags-container.expanded {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Three columns per row */
    gap: 10px; /* Adjust the gap between tags as needed */
    padding: 0 5px;
}

.tags-container.collapsed {
    display: flex;
    width: var(--container-width-smartphone) !important;
    background: var(--background-less-dark) !important;
    border-radius: 5px;
    background: var(--element-background) !important;
    height: 35px !important;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    border-bottom: 2px solid var(--element-background);
}

.tag {
    flex-grow: 0;
    flex-shrink: 1;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 8px;
    transition: all 0.3s ease;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    height: 50px;
}

.text-tag {
    color: var(--border-color);
    font-family: "Upheaval TT (BRK)", sans-serif;
    font-weight: bold;
    font-size: 12px;
}

.tag img { /* Adjust as needed for your icons */
    width: 35px;
    height: 35px;
    transition: fill 0.3s ease;
}

.tag.selected, .tag.selected:hover {
    display: flex;
    color: var(--text-color);
}

.text-wrapper.selected {
    border-bottom: 2px solid var(--monitor-shadow-color);
}

/** Button styles */
.tag-icon {
    display: block;
    max-width: 100%;
    height: auto;
    position: relative;
    margin: 0 auto;
    transition: filter 0.3s ease-in-out;
}

.tag-icon.highlight {
    filter: brightness(1.5) drop-shadow(0 0 3px rgba(70, 205, 132, 0.7));
}

.activity-tag {
    text-align: end;
    width: 100%;
    justify-content: end;
    display: flex;
    align-items: center;
}

.activity-tag.unselected {

}

.activity-tag div {
    margin-right: 10px;
}

.activity-tag.unselected div {
        color: var(--disabled-text) !important;
}

.section-title {
    font-size: 16px;
    font-family: 'Upheaval TT BRK', sans-serif;
    color: var(--text-color);
    padding-top: 16px;
    text-align: initial;
    padding-left: 16px;
}