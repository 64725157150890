.item {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    min-width: 320px;
    position: static;
}

.detail {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.detailExpanded {
    max-height: 1000px;
    transition: max-height 1.2s ease;
}

.item-title {
    min-width: 50px;
}

.item-header {
    display: flex;
    gap: 15px;
    transition: max-height 1.2s ease;
}

.details-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--background-less-dark);
    background-image: var(--texture);
    overflow: auto;
}

.overlay-content {
    background: var(--background-less-dark);
    background-image: var(--texture);
    max-height: 100%;
    overflow-x: hidden;
    padding-bottom: 15px;
    text-align: center;
}

.selection-option {
    background: var(--background-dark);
    height: 58px;
    cursor: pointer;
    width: 33.33%;
}

.selection-option.central {
    border-left: 2px solid var(--border-color);
    border-right: 2px solid var(--border-color);
}

.selection-option-inner {
    height: 50px;
    border-bottom: 2px solid var(--border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--background-less-dark);
    transition: transform 0.3s ease;
    font-family: 'IBM Plex Mono', sans-serif;
}

.selection-option-inner.translate {
    font-family: 'IBM Plex Mono', sans-serif;
    box-shadow: 0 6px 0 0 #0003 inset;
    transform: translateY(8px);
    background: var(--pressed-button-color);
    color: var(--element-background);

}

.selection-option-inner.left {
    border-bottom-left-radius: 5px;
}

.details.visible {
    display: block; /* Show details when visible */
}

.selection-options {
    justify-content: center;
    display: flex;
    border: 2px solid var(--border-color);
    border-top: none;
    border-radius: 0 0 5px 5px;
    width: var(--container-width-smartphone);
    margin: 0 auto;
    box-shadow: 0 8px 6px rgba(0, 0, 0, 0.4);
}

.vehicle-image-container {
    text-align: left;
    margin-right: 20px; /* Adjust based on layout */
    width: 100px; /* Adjust based on your image size */
}

.vehicle-image {
    width: 100%;
    height: auto;
}

.vehicle-details {
    color: darkgray;
    font-size: smaller;
}

.selection-option.selected {
    background: var(--background-dark) !important;
}

.option-stars {
    color: black;
    text-align: center;
}

.image-placeholder {
    border: 1px solid gray;
    text-align: center;
    padding: 20px; /* Adjust as needed */
    margin: 20px 0; /* Spacing around the placeholder */
}


/*  Styling of timeline */

.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: var(--container-width-smartphone);
    margin: 0 auto;
    gap: 25px;
    padding-top: 60px;
}

.timeline-item {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    background: inherit;
    cursor: pointer;
    max-width: var(--container-width-smartphone);
}


.event-container {
    width: 280px;
    border: 2px solid;
    border-left: none;
    border-radius: 0 5px 5px 0;
    box-shadow: var(--shadow-offer-item);
}

.event-container, .timeline-icon {
    height: 50px;
    background: var(--background-dark);
}

.event-container-inner, .timeline-icon-inner {
    height: 41px;
    background: var(--background-less-dark);
}

.event-container-inner {
    border-bottom: 2px solid;
    width: 100%;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    align-content: center;
}

.time-between {
    display: flex;
    justify-content: flex-start; /* Aligns content to the left */
    align-items: center;
    font-size: 10px;
    position: relative;
    left: 0;
    width: 100%;
    padding-left: 10px;
}

.timeline-icon {
    z-index: 2;
    width: 43px;
    border: 2px solid var(--border-color);
    box-shadow: var(--shadow-offer-item);
}

.timeline-icon-inner {
    display: grid;
    place-items: center;

    width: 100%;
    margin: 0 auto;
    border-bottom: 2px solid var(--border-color);
}

.flight-arrow-icon {
    width: 100px;
    height: auto;
}

.event-info {
    position: relative;
    background: var(--screen-shadow);
    background-image: var(--texture2);
    border: 2px solid var(--border-color);
    width: 255px;
    height: 25px;
    margin: 0 auto;
    display: flex;
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
    flex-direction: row;
    border-radius: 5px;
}


.number-of-stops, .middle-airport {
    font-size: 11px;
    text-align: center;
}

.check-in-time {
}