@import url('../../commonStyles.css');

.button-text {
    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
}

.button-city {
    font-family: 'IBM Plex Mono', sans-serif;
    background-color: var(--background-dark) !important;
    width: 100%;
}

.button-city-inner {
    transition: transform 0.3s ease, color 0.3s ease, background-color 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    position: relative;
    background: var(--background-less-dark);
    z-index: 10;
}

.destination-name {
    text-align: center;
    font-size: 22px;
    max-height: 40px;
    overflow: clip;
    font-family: 'Upheaval TT BRK', sans-serif;
    color: var(--text-color);
}


.button-outer {
    cursor: pointer;
    width: 12.5%;
    height: 48px;
    border: 2px solid var(--border-color);
}

.button-inner {
    height: 40px;
    border-bottom: 2px solid var(--border-color);
    display: flex;
    vertical-align: center;
    align-content: center;
}

.button-inner svg {
    margin: auto;
}

.button-selection-outer {
    height: 48px;
    width: 190px;
    border: 2px solid var(--border-color);
    background-color: var(--background-dark);
}

.button-selection-inner {
    height: 40px;
    border-bottom: 2px solid var(--border-color);
    background-color: var(--background-less-dark);
    display: flex;
    align-items: center;
    justify-content: center;
}

.small-monitor {
    background-color: var(--screen-light);
    background-image: var(--texture);
    width: 170px;
    height: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--border-color);
    border-radius: 4px;
    font-size: 14px;
    color: var(--border-color);
    font-weight: bold;
    font-family: "IBM Plex Mono", serif;
}

.small-monitor.translate {
    background-color: var(--screen-shadow);
}

.middle-button {
    border-right: none !important;
    border-left: none !important;
}

.button-power-outer {
    width: 32px;
    height: 32px;
    background: var(--navigation-button-outer);
    border: 2px solid var(--border-color);
    border-radius: 5px;
    margin-right: 13px;
}

.button-power-outer.pressed {
    background: var(--background-dark);
}

.button-power-inner {
    width: 100%;
    height: 25px;
    background: var(--navigation-button-inner);
    border-radius: 5px;
    border-bottom: 2px solid var(--border-color);
    transition: transform 0.3s ease;
}

.button-power-inner.pressed {
    transform: translateY(5px);
    background: var(--background-dark);
    border-top: 2px solid var(--border-color);
}

.block-title {
    font-family: 'Upheaval TT BRK', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 19px;
    color: var(--screen-light);
    align-content: center;
    margin-left: 24px;
    margin-top: 12px;
}

.off-button {
    padding: 10px;
}