
.date-range-picker-container {
    width: 100%;
}

.custom-calendar {
    width: 100%;
    overflow: hidden;
    transition: height 1s ease, bottom 1s ease; /* Smooth transition */
    z-index: 50;
    display: block !important;
}

.calendar-header {
    width: 350px;
    height: 80px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    margin: 10px auto 0;
    background: var(--background-less-dark);
}

.calendar-header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;
    border-radius: 5px;
    height: 20px;
    font-family: Aspira, sans-serif;
    font-weight: bold;
    color: var(--accent-text);
    font-size: 18px;
}

.calendar-days {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    background-color: inherit;
    height: 255px;
    margin: 0 auto 10px;
    width: 330px;
    align-items: center;
}

.calendar-days.tight-rows {
    height: 210px !important;
}

.very-tight-rows {
    height: 160px !important;
}

.datepicker-day-empty {
    border: none;
}

.datepicker-day.active, .datepicker-day.in-range {
    background-color: var(--monitor-shadow-color);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3) !important;
}

.datepicker-day-inner.translate {
    transform: translateY(6.5px);
    border-top: 2px solid var(--border-color);
    background-color: var(--pressed-button-color) !important;
    background-image: none !important;
}

.datepicker-day-inner.translate-in-range {
    background-color: var(--navigation-button-inner) !important;
}


.datepicker-day-names {
    display: flex;
    justify-content: space-between;
    margin: 10px auto;
    max-width: 330px;
}

.datepicker-day-name {
    flex: 1 1;
    text-align: center;
    font-size: 13px;
    height: 25px;
    width: 30px !important;
    margin: 2px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    padding-top: 6px;
    font-family: Aspira, sans-serif;
    font-weight: bold;
    color: var(--element-background);
    background: var(--monitor-shadow-color);
}

.datepicker-day.active .datepicker-day-inner {
    background-color: var(--background-dark);
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    box-shadow: 0 4px 0 0 #00000040 inset;
}

.datepicker-day {
    height: 35px;
    width: 37px;
    flex: 1 0 14.28%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12%;
    cursor: pointer;
    position: relative;
    transition: background-color 1s, transform 0.3s;
    margin: 0 auto;
}

.day-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: 14px;
    font-weight: bold;
    font-family: Aspira, sans-serif;
    color: #505050;
}

.day-number.disabled {
    text-decoration: line-through;
    text-decoration-color: var(--accent-text);
    text-decoration-thickness: 2px;
}

/* Add this to your styles.css */
.datepicker-day.disabled {
    position: relative; /* Ensure the pseudo-element is positioned relative to the parent */
}

.datepicker-day-name.disabled, .disabled {
    pointer-events: none;
    background-image: none !important;
    color: var(--accent-text);
}

.datepicker-day-inner.disabled {
    background-color: var(--background-dark) !important;
    background-image: none !important;
}

.month-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.last-month {
    padding-bottom: 50px;
}

.months-container {
    max-width: 330px;
    justify-content: center;
    flex-direction: column;
    margin: 20px auto 0;
}

.datepicker-navigation {
    width: 330px;
    z-index: 3000;
    height: 60px;
    background: var(--element-background);
    padding-top: 20px;
}

.calendar-open {
    height: 45vh;
    overflow-y: auto;
}

div.unselected {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
}

.line.unselected {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: var(--disabled-text) !important;
}
