.carousel-wrapper {
    width: 90%;
    margin: 55px auto;
}

h2 {
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--text-color);
}

.offer-title {
    margin: auto;
    width: 150px;
    text-align: center;
    color: var(--disabled-text);
    font-size: 22px;
}

.offers-container {
    background-color: var(--background-less-dark);
    background-image: var(--texture);
    height: 100vh;
    overflow-y: auto;
}

.offer-header {
    /*width: var(--container-width-smartphone);*/
    width: 331px;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    box-shadow: var(--shadow-offer-item);
    margin: 20px auto 5px;
}

.images-button-outer {
    cursor: pointer;
    z-index: 2;
    width: 90%;
    height: 60px;
    background: var(--background-less-dark);
    transition: background-color 0.3s ease;
    border: 2px solid var(--border-color);
    border-radius: 0 0 5px 5px;
}

.images-button-inner {
    transition: transform 0.1s ease;
    height: 50px;
    border-bottom: 2px solid var(--border-color);
    display: flex;
    flex-direction: row;
    place-items: center;
    position: relative;
    border-radius: 0 0 5px 5px;
    gap: 20px;
    padding-left: 20px;
}

.offers-title {
    color: var(--text-color);
    font-weight: bold;
    text-align: center;
}

.offers-title-container {
    width: var(--container-width-smartphone);
    background: var(--background-less-dark);
    border-radius: 5px;
    margin: 0 auto;
}

.offers-title-container-inner {
    width: 300px;
    background: var(--background-less-dark);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.date-range {
    font-weight: bold;
    font-size: 11px;
    margin-bottom: 15px;
}

.images-button-inner.translate {
    transform: translateY(10px);
    border-bottom: none !important;
    background-color: var(--navigation-button-inner);
}


.selection-option.translate {
    box-shadow: 0 6px 0 0 #0003 inset;
}

.offers-monitor-outer {
    width: var(--container-width-smartphone);
    background-color: var(--background-dark);
    border-radius: 4px 4px 1px 1px;
}

.offers-monitor {
    height: 60px;
    background-color: var(--screen-light);
    background-image: var(--texture2);
    border: 2px solid var(--border-color);
    border-radius: 5px;
}

.grand-total, .vacation-range, .grand-total-icons {
    margin: auto;
    text-align: center;
    color: var(--disabled-text);
    font-size: 11px;
}

.vacation-range {
    border-bottom: 1px solid var(--disabled-text);
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 3px;
    margin-top: 4px;
}

.grand-total-wrapper {
    display: flex;
    flex-direction: row;
}

.grand-total, .grand-total-icons {
    margin-top: 4px;
}
