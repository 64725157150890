.city-information-wrapper {
    width: 100%;
    background-color: var(--background-less-dark);
    background-image: var(--texture);
    z-index: 10;
    overflow: hidden;
    position: absolute;
    transition: height 1s ease, bottom 1s ease; /* Smooth transition */
    display: none;
}

.city-information-wrapper.open {
    height: 85vh;
    border-top: 2px solid var(--border-color);
    z-index: 50;
    background-color: var(--background-less-dark);
    border-bottom: 2px solid var(--border-color);
    display: block !important;
    bottom: 0;
}

.city-information-content {
    width: 100%;
    height: 100%;
}

.city-info-header {
}

.x-button {
    align-content: center;
    position: absolute;
    right: 8px;
    top: 10px;
}