.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    #background-color: var(--background-less-dark);
    background-color: var(--monitor-shadow-color);
    height: 40px;
    z-index: 50;
    position:fixed;
    top:0;
    width:100%;
}

.profile-icon {
    display: flex;
    align-items: flex-start;
}


.logo {
    margin-left: 10px;
    margin-top: 2px;
    position: absolute;
    z-index: 50;
    cursor: pointer;
}

.exit-button-outer {
    cursor: pointer;
    z-index: 2;
    height: 40px;
    width: 35px;
    background-image: none !important;
    background: var(--navigation-button-outer);
    transition: background-color 0.3s ease;
    border: 2px solid var(--border-color);
    border-radius: 5px;
    margin-right: 20px;
    margin-top: 20px;
}

.exit-button-inner {
    transition: transform 0.3s ease;
    height: 30px;
    width: 35px;
    border-bottom: 2px solid var(--border-color);
    background: var(--navigation-button-inner);
    background-image: var(--texture);
    display: flex;
    place-items: center;
    position: relative;
    border-radius: 5px;
}

.exit-button-inner svg {
    margin: 0 auto;
}

.exit-button-inner.translate {
    transform: translateY(7px);
    border-bottom: none !important;
    background-color: var(--navigation-button-inner);
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color) !important;
}

.profile-icon {
    margin-right: 10px;
}