.duration-text {
    font-size: 11px;
    color: var(--disabled-text);
    margin-bottom: 3px !important;
    margin-top: -5px !important;
    font-weight: bold;
}

.more-details {
    color: var(--background-dark) !important;
}

.container-info {
    width: var(--container-width-smartphone);
    border: 2px solid var(--border-color);
    margin: 5px auto 0;
    border-radius: 5px 5px 0 0;
    height: 225px;
    background: var(--background-less-dark);
}

.flight-container {
    height: 267px !important;
}

.container-info-inner {
    width: 100%;
    height: 146px;
    align-content: center;
    margin-left: 17px;
    margin-top: 5px;
}

.transfer-wrapper {
    width: var(--container-width-smartphone);
    border: 2px solid var(--border-color);
    margin: 15px auto 0;
    border-radius: 5px 5px 0 0;
    height: 370px;
}

.transfer-wrapper-inner {
    height: 330px;
    align-content: center;
    background: var(--background-less-dark);
    display: flex;
    align-items: center; /* Centers the child vertically */
    justify-content: center; /* Centers the child horizontally */
}

.flight-info-inner {
    width: 294px;
    box-shadow: 0 7px 0 0 #00010085 inset;
    margin: 15px auto 0;
    border-radius: 5px;
}

.hotel-info-inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: 126px;
    box-shadow: 0 7px 0 0 #00010055 inset;
    background: var(--background-dark);
    border-radius: 5px;
    width: 294px;
}

.transfer-info-inner {
    height: 283px;
    box-shadow: 0 7px 0 0 #00010055 inset;
    width: 285px;
    border-radius: 5px;
    background: var(--background-dark);
}

.flight-monitor, .hotel-monitor, .transfer-monitor {
    background: var(--screen-light);
    background-image: var(--texture2);
    /* box-shadow: var(--details-monitor-shadow); */
    border-radius: 5px;
    border: 2px solid var(--border-color);
}

.segments-monitor {
    background: var(--background-light);
    /* box-shadow: var(--details-monitor-shadow); */
    border-radius: 5px;
    border: 2px solid var(--border-color);
}

.selection-title, .flight-section-title {
    width: 250px;
    color: var(--disabled-text);
    margin: 5px 20px;
    font-family: 'Upheaval TT BRK', sans-serif;
    font-size: 18px;
}

.flight-section-title {
    font-size: 18px;
}

.selection-title {
    font-size: 22px;
    width: 250px;
    color: var(--disabled-text);
    margin: 5px 20px;
    font-family: 'Upheaval TT BRK', sans-serif;
    text-align: center;
}

.separator-line {
    border-bottom: 1px solid var(--disabled-text);
    width: 90%;
    margin: 0 auto;
}

.separator-dark-line {
    border-bottom: 1px solid var(--background-dark);
    width: 90%;
    margin: 7px auto 0;
}

.hotel-monitor {
    height: 185px;
    width: 100%;
}

.transfer-monitor {
    height: 280px;
}

.deep-effect {
    box-shadow: var(--details-monitor-shadow);
    margin-top: 6px;
}

/* Optionally, if you want to ensure there's some space between the elements */

.flight-details-container, .hotel-details-container, .flight-segments-container, .transfer-details-container {
    display: flex;
    width: 280px;
    margin: 12px auto;
}

.item-selection-container {
    min-height: 170px;
}

.hotel-details-container {
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
}

.transfer-details-container {
    flex-direction: row;
}

.transfer-details-departure, .transfer-details-arrival {
    display: flex;
    flex-direction: column;
    font-family: 'Upheaval TT BRK', sans-serif;
    color: var(--disabled-text);
    width: 80px;
}

.transfer-details-departure {
    margin-right: 15px;
    margin-left: 5px;
    text-align: start;
    align-items: start;
}

.transfer-details-arrival {
    margin-right: 5px;
    margin-left: 5px;
    text-align: end;
    align-items: end;
}

.transfer-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: var(--screen-shadow);
    font-family: 'Upheaval TT BRK', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 16px;
    text-align: center;
    padding-bottom: 4px;
    width: 90%;
    margin: -10px auto 0;
    gap: 3px;
    padding-top: 7px;
}

.transfer-image svg {
    width: 24px;
    height: 24px;
}

.transfer-image svg path {
    stroke: var(--screen-shadow) !important;
}

.transfer-image {
    stroke: var(--screen-shadow) !important;
    color: var(--screen-shadow);
    text-align: center;
}

.transfer-title svg {
    width: 30px;
    height: 30px;
}

.transfer-title svg path {
    stroke: var(--screen-shadow) !important;
}

.accommodation-name, .airport-name {
    font-size: 22px;
}

.city-text {
    font-family: "IBM Plex Mono", serif;
    font-size: 12px;
}

.departure {
    text-align: left;
}

.arrival {
    text-align: right;
}

.left {
    border-bottom-left-radius: 5px
}

.right {
    border-bottom-right-radius: 5px
}

.detail-title {
    order: 1; /* Ensure check-in text appears first */
    font-size: 12px;
    font-weight: bold;
}

.detail-information {
    order: 2;
    font-weight: bold;
    font-size: 13px;
    margin-top: 6px;
}

.hotel-name {
    color: var(--disabled-text);
    font-size: 20px;
    font-family: 'Upheaval TT BRK', sans-serif;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--disabled-text);
    width: 90%;
    max-height: 38px;
    overflow: hidden;
}

.accommodation-details {
    display: flex;
    flex-direction: row;
    gap: 5px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
}

.check-in-title, .room-title, .check-out-title {
    color: var(--screen-shadow);
    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    min-width: 70px;
}

.check-in-time, .check-in-date, .check-in-title {
    text-align: left;
}

.room-title {
    text-align: center;
}

.check-out-time, .check-out-date, .check-out-title {
    text-align: right;
}

.check-out-details {
    margin-right: 8px;
}

.check-in-details {
    margin-left: 8px;
}

.check-in-date, .check-in-time, .check-out-date, .check-out-time, .room-info {
    color: var(--disabled-text);
    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 10px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03em;
}

.room-info {
    text-align: center;
    height: 55px;
    overflow: clip;
}

.images-container-outer {
    height: 350px;
    position: relative;
    display: inline-block;
    width: var(--container-width-smartphone);
    margin: auto 0;
    border-radius: 5px;
    background: var(--background-less-dark);
}

.images-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px auto 0;
    width: 90%;
    height: 241px;
    border-radius: 5px;
    background: var(--background-less-dark);
}

.images-container-inner {
    background: var(--background-less-dark);
    background-image: var(--texture);
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    height: 240px;
    border: 2px solid var(--border-color);
}

.images-container-inner.selected {
    background: var(--pressed-button-color) !important;
}

.images-wrapper-outer {
    margin: 30px auto 10px;
    height: 360px;
    border: 2px solid var(--border-color);
    width: var(--container-width-smartphone);
    border-radius: 5px;
    background: var(--background-dark);
}

.images-wrapper {
    height: 354px;
    text-align: center;
    flex: 1;
    padding-bottom: 120px;
}

.image {
    width: 100%;
    height: 240px;
    overflow: clip;
}

.image-navigation {
    position: absolute;
    bottom: 5px;
    background: rgba(0, 0, 0, 0.5);
    color: var(--screen-light);
    padding: 5px 10px;
    border-radius: 3px;
    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03em;
    text-align: center;
}

.navigation-buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 10px auto;
}

.transfer-details {
    margin: 10px auto;
    color: var(--screen-shadow);
    font-family: 'IBM Plex Mono', sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.03em;
    text-align: center;
}

.duration-details {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.single-button {
    width: 100%;
}

.one-of-two {
    width: 100%;
}

.transfer-header {
    width: 100%;
    height: 40px;
    display: flex;
    background: var(--background-less-dark);
    justify-content: space-between;
}
