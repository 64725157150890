/* Fullscreen overlay that covers the entire viewport */
.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Gray background with slight transparency */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure the overlay is on top */
}

/* Container for the image and navigation controls */
.fullscreen-content {
    position: relative;
    max-width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

/* Fullscreen image styling */
.fullscreen-image {
    width: 55vh;
    height: auto;
    max-height: 90vh; /* Maintain aspect ratio, maximum height of the viewport minus some padding */
    object-fit: cover; /* Ensures the image is stretched to the left and right */
}

/* Image counter styling */
.image-counter {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: var(--screen-light);
    font-size: 16px;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    padding: 5px 10px;
    border-radius: 5px;
}

/* Navigation buttons (previous and next) */
.nav-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    color: var(--screen-light);
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 12px 8px 19px;
    border-radius: 5px;
    margin: 0;
    line-height: 15px;
}

.prev-button {
    left: 10px;
}

.next-button {
    right: 10px;
}

/* Close button in the top right corner */
.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    border-radius: 5px;
    color: var(--screen-light);
    font-size: 24px;
    cursor: pointer;
    z-index: 1001; /* Ensure button is above content */
    padding: 7px;
    background-color: rgba(0, 0, 0, 0.5);
}

/* Ensure buttons have a larger click area */
.nav-button:hover, .close-button:hover {
    color: var(--screen-light); /* Slight color change on hover */
}


.amenities-wrapper {
    width: 100%;
    box-sizing: border-box;
}

.amenities-content {
    width: var(--container-width-smartphone);
    margin: 50px auto 145px;
    padding-bottom: 145px;
}

.amenities-list {
    margin-top: 10px;
}

.amenity-group {
    margin-bottom: 20px;
}

.amenities-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--background-dark);
    margin-bottom: 10px;
}

.amenities-items {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.amenity-item {
    padding-left: 4px;
    text-align: left;
    color: var(--background-dark);
    margin-bottom: 5px;
    font-size: 12px;
}

.images-buttons-wrapper {
    height: 78px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 2px solid var(--border-color);
    border-bottom: 2px solid var(--border-color);
    margin-top: 15px;
    border-radius: 0 0 5px 5px;
}

.detail-title {
    margin-top: 10px;
    text-align: center;
    font-size: 11px;
    margin-left: 7px;
    font-family: "IBM Plex Mono";
    color: var(--disabled-text);
}

.detail-text {
    text-align: center;
    font-size: 12px;
    margin-left: 7px;
    font-family: "IBM Plex Mono";
    color: var(--disabled-text);
}

.more-details-title {
    text-align: center;
}