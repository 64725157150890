@import url('../../../commonStyles.css');

.destination-title {
    color: var(--screen-light);
    text-align: left;
    margin-left: 20px;
    width: 90%;
    font-size: 22px;
    max-height: 21px;
}

.destination-container-outer {
    position: relative;
    display: inline-block;
    width: var(--container-width-smartphone);
    margin: auto 0;
    border-radius: 5px;
    background: var(--background-less-dark);
    border-bottom: 2px solid var(--border-color);
}
