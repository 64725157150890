/*colors */
/*:root {*/
/*    --background-less-dark: #205a65;*/
/*    --background-dark: #07343D;*/
/*    --background-light: #18788A;*/
/*    --screen-light: #46CD84;*/
/*    --screen-shadow: #298855;*/
/*    --disabled-text: #1B633C;*/
/*    --navigation-button-outer: #CC5932;*/
/*    --navigation-button-inner: #FF6F3E;*/
/*    --element-background: white;*/
/*    --pressed-button-color: purple;*/
/*    --error-color: red;*/
/*    --border-color: black;*/
/*    --text-color: black;*/
/*    --text-color-light: white;*/
/*    --monitor-shadow-color: #46CD84CC;*/
/*    --details-monitor-shadow: 0 7px 0 0 #00010055 inset;*/

/*    --texture: url('images/backgrounds/tekstura-new-2.png');*/
/*    --texture2: url('images/backgrounds/tekstura-input.png');*/

/*    --shadow-offer-item: 0px 6px 0px 0px #00000033;*/
/*    --container-width-smartphone: 330px;*/
/*    --container-width-wrapper: 300px;*/
/*}*/

/** light theme **/
:root {
    --background-less-dark: #f2f2f2;
    --background-dark: #848484;
    --background-light: #DFDFDF;
    --screen-light: #CD8F46;
    --screen-shadow: #A26E32;
    --disabled-text: #a8a8a8;
    --accent-text: #b3b3b3;
    --navigation-button-outer: #503bf5;
    --navigation-button-inner: #503bf5;
    --element-background: white;
    --pressed-button-color: #A26E32;
    --error-color: red;
    --border-color: #ababab;
    --text-color: #666666;
    --text-color-light: white;
    --monitor-shadow-color: #46CD84;
    --details-monitor-shadow: 0 7px 0 0 #00010055 inset;

    --texture: url('images/backgrounds/tekstura-new-2.png');
    --texture2: url('images/backgrounds/tekstura-input.png');


    --container-width-smartphone: 350px;
    --container-width-wrapper: 300px;
}

/********************/

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Prevents scrolling on the body */
    background: var(--background-less-dark);
    background-image: var(--texture);
}

.page-title {
    color: var(--element-background);
    font-family: 'Upheaval TT BRK', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    border: none;
    margin: 10px auto;
    width: var(--container-width-smartphone);
}

.spinner-container {
    margin-top: 100px;
    display: flex;
    justify-content: center; /* horizontally center the content */
    align-items: center; /* vertically center the content */
}

.error-detail {
    color: var(--error-color);
    font-size: 10px;
    font-weight: bold;
    font-family: 'IBM Plex Mono', serif;
    width: 90%;
    margin: 7px auto;
}

.error-message {
    font-size: 11px;
    font-weight: bold;
    width: 90%;
    margin: 40px auto 20px;
    text-align: center;
}

@font-face {
    font-family: 'Upheaval TT BRK';
    src: url('fonts/upheavtt.woff') format('woff'),
    url('fonts/upheavtt.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IBM Plex Mono';
    src: url('fonts/plex_mono/IBMPlexMono-SemiBold.woff') format('woff'),
    url('fonts/plex_mono/IBMPlexMono-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Upheaval TT BRK', sans-serif;
    border: none;
}

input {
    font-family: Aspira, sans-serif;
}

.wide {
    min-width: 200px !important;
}

.highlight {
    /* Adjust the brightness if needed */
    filter: brightness(1.2);

    background-color: var(--screen-light) !important;
}

.input-box-title {
    color: var(--text-color);
    font-family: Aspira, sans-serif;
    padding-top: 15px;
    padding-bottom: 5px;
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    margin-left: 15px;
}

.logo-title {
    color: var(--background-light);
    font-family: 'Upheaval TT BRK', sans-serif;
    font-size: 22px;
    font-weight: 400;
    line-height: 19px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bottom-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.strong-shadow {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}


.rounded-button {
    border-radius: 5px;
}

.rounded-left-button {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rounded-right-button {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.rounded-bottom-button {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.rounded-top-button {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

.nav-button-outer.ready {
    background-color: var(--navigation-button-outer);
    background-image: none !important;
}

.nav-button-outer {
    background-color: var(--navigation-button-outer);
    background-image: none !important;
    height: 40px;
    width: 290px;
    align-content: center;
    margin: 0 auto;
    color: var(--element-background);
    font-weight: bold;
}


.nav-button-inner.ready {
    background-color: var(--navigation-button-inner);
    background-image: none !important;
    color: var(--text-color);
}

.nav-button-inner {
    background-color: var(--navigation-button-inner);
    background-image: none !important;
    color: var(--background-less-dark);
}

.monitor {
    background-color: var(--screen-light);
    background-image: var(--texture2);
}

.main-color{
    background: black !important;
}