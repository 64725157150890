@import url('../../../commonStyles.css');

.results-wrapper {
    width: 360px;
    border-radius: 5px;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
}

.city-teaser {
    width: 350px;
    border-radius: 5px;
    height: 70px;
    margin: 0 auto;
    margin-bottom: 15px;
    background: var(--element-background);
    display: flex;
}

.left-info {
    display: flex;
    flex-direction: column;
    align-content: start;
    justify-content: center;
    margin-left: 10px;
}

.city-name {
    font-size: 18px;
    color: black;
}

.country-name {
    font-size: 14px;
    color: var(--text-color);
}

.city-name, .country-name {
    font-family: Aspira, sans-serif;
    text-align: initial;
}

/* Scrollable wrapper settings */
.scrollable-wrapper {
    height: 60vh;
    overflow-y: scroll;
    padding-bottom: 20px; /* Space at the bottom of the scrollable area */
    border-radius: 5px;
}

/* Add margin to the first and last .city-teaser to add space */
.scrollable-wrapper .city-teaser:first-child {
    margin-top: 0; /* No extra space above the first city-teaser */
}

.scrollable-wrapper .city-teaser:last-child {
    margin-bottom: 0; /* No extra space below the last city-teaser */
}
